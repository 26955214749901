import React from 'react';
import autobind from 'autobind-decorator';

import SpecBlock from './SpecBlock';
import ArrowIcon from './ArrowIcon';

@autobind
export default class SpecRow extends React.Component {
	
	static propTypes = {
		name: React.PropTypes.string.isRequired,
		data: React.PropTypes.array.isRequired,
	}
	
	constructor() {
		super();
		
		this.state = {
			
		};
	}
	
	onClickTitle (name) {
		if (typeof this.props.onClick === 'function') this.props.onClick(name);
	}
	
	render() {
		
		let {name, data, expanded} = this.props;
		let {onClickTitle} = this;
		
		return (
			<div className={`specRow m-specRow-spec ${expanded?'s-expanded':''}`}>
				<h3 className="specRow-title" onClick={onClickTitle.bind(null, name)}>{name} <ArrowIcon direction={expanded?'down':'up'} /></h3>
				<div className="specRow-blockWrap">
					<SpecBlock txt={data[0]} />
					<SpecBlock txt={data[1]} />
				</div>
			</div>
		);
	}
}
