import React from 'react';
import ReactDom from 'react-dom';

import autobind from 'autobind-decorator';


// components
import VariantRow from './components/VariantRow';
import SpecRow from './components/SpecRow';

@autobind
export default class SpecViewerApp extends React.Component {
	
	constructor() {
		super();
		
		this.state = {
			expandStatus: {
				'Powertrain': true,
				'Performance': false,
				'Chasis': false,
				'Dimension & Weight': false,
				'Safety & Security': false,
				'Interior': false,
				'Accessories': false,
				'Exterior': false,
				'Colours': false,
			},
			selectedVariants: ['variant1', 'variant2']
		};
	}
	
	onToggleRow (name) {
		this.state.expandStatus[name] = !this.state.expandStatus[name];
		this.forceUpdate();
	}
	
	onChangeVariant (columnIndex, newVariant) {
		this.state.selectedVariants[columnIndex] = newVariant;
		this.forceUpdate();
	}
	
	
	getSpecDataByName (name) {
		let computedSpecData = this.state.selectedVariants.map( value => this.props.data[value][name] );
		return computedSpecData;
	}
	
	render() {
		
		let {expandStatus:es, selectedVariants} = this.state;
		let {onToggleRow:otr, onChangeVariant, getSpecDataByName} = this;
		
		return (
			<div className="specViewerApp">
				<VariantRow onChange={onChangeVariant} selected={selectedVariants} />
				
				<SpecRow name="Powertrain" data={getSpecDataByName('Powertrain')} expanded={es['Powertrain']} onClick={otr} />
				<SpecRow name="Performance" data={getSpecDataByName('Performance')} expanded={es['Performance']} onClick={otr} />
				<SpecRow name="Chasis" data={getSpecDataByName('Chasis')} expanded={es['Chasis']} onClick={otr} />
				<SpecRow name="Dimension & Weight" data={getSpecDataByName('Dimension & Weight')} expanded={es['Dimension & Weight']} onClick={otr} />
				<SpecRow name="Safety & Security" data={getSpecDataByName('Safety & Security')} expanded={es['Safety & Security']} onClick={otr} />
				<SpecRow name="Interior" data={getSpecDataByName('Interior')} expanded={es['Interior']} onClick={otr} />
				<SpecRow name="Accessories" data={getSpecDataByName('Accessories')} expanded={es['Accessories']} onClick={otr} />
				<SpecRow name="Exterior" data={getSpecDataByName('Exterior')} expanded={es['Exterior']} onClick={otr} />
				<SpecRow name="Colours" data={getSpecDataByName('Colours')} expanded={es['Colours']} onClick={otr} />
			</div>
		);
	}
};



ReactDom.render(<SpecViewerApp data={window.specsData} />, document.querySelector('#specViewer'));






