import React from 'react';
import autobind from 'autobind-decorator';

@autobind
export default class SpecBlock extends React.Component {
	
	constructor() {
		super();
		
		this.state = {
			
		};
	}
	
	render() {
		return (
			<div className="specBlock">
				{this.props.txt}
			</div>
		);
	}
}
