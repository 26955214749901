import React from 'react';
import autobind from 'autobind-decorator';

import VariantBlock from './VariantBlock';

@autobind
export default class VariantRow extends React.Component {
	
	constructor() {
		super();
		
		this.state = {
			
		};
	}
	
	render() {
		
		let {selected} = this.props;
		
		return (
			<div className="specRow m-specRow-variant">
				<VariantBlock onChange={this.props.onChange} index={0} selected={selected[0]} />
				<VariantBlock onChange={this.props.onChange} index={1} selected={selected[1]} />
			</div>
		);
	}
}
