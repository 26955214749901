import React from 'react';
import autobind from 'autobind-decorator';

@autobind
export default class VariantBlock extends React.Component {
	
	constructor() {
		super();
		
		this.state = {
			
		};
	}
	
	onSelectChange (event) {
		if (typeof this.props.onChange === 'function') this.props.onChange(this.props.index, event.target.value);
	}
	
	render() {
		
		let {selected} = this.props;
		return (
			<div className="variantBlock">
				<select className="variantBlock-select" onChange={this.onSelectChange} value={selected} disabled>
					<option value="variant1">Variant 1</option>
					<option value="variant2">Variant 2</option>
				</select>
			</div>
		);
	}
}
